// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/* global ajax_object */

; // eslint-disable-line

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  // anchor scroll
  let $headerHeight = $('.elementor-element-5aca9a2f').outerHeight();
  $(document).on('click', '#anchor-scroll', function (event) {
    event.preventDefault();

    $('html, body').animate(
      {
        scrollTop: $($(this).attr('href')).offset().top - $headerHeight,
      },
      400
    );
  });
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  $('.vegetable-slider').slick({
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    appendArrows: $('.slider-section__arrows'),
    prevArrow: $('.slider-section__arrows-prev'),
    nextArrow: $('.slider-section__arrows-next'),
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 568,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  });

  $('.slider-family').slick({
    infinite: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: $('.family-arrow__prev'),
    nextArrow: $('.family-arrow__next'),
  });

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  $(window).on('scroll', function () {
    if ($(window).scrollTop() > 50) {
      $('.elementor-location-header').addClass('active-header');
    } else {
      //remove the background property so it comes transparent again (defined in your css)
      $('.elementor-location-header').removeClass('active-header');
    }
  });

  /**
   * AJAX CATEGORY FILTER
   */
  let perpage = 6;

  function loadPosts(response, taxonomy = false) {
    let offset = taxonomy ? 0 : $('.posts__wrapper-block').length;
    let category = $('.sorting-posts__tax').find('.active').data('category');

    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: ajax_object.ajax_url,
      data: {
        action: 'filtration_ajax',
        offset: offset,
        category: category,
        ppp: perpage,
      },
      success: function (response) {
        if (taxonomy) {
          $('#ajax-posts').html(response.html);
          $('.more-posts').show();
        } else {
          $('#ajax-posts').append(response.html);
        }
        if ($('.posts__wrapper-block').length >= response.post_count) {
          $('.more-posts').hide();
        }
      },
      error: function (result) {
        console.warn(result);
      },
    });
    return false;
  }

  $('.more-posts').on('click', function () {
    loadPosts('response');
  });
  $('.posts__tax-link').click(function (e) {
    e.preventDefault();
    $('.posts__tax-link').removeClass('active');
    $(this).addClass('active');
    loadPosts('response', true);
  });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  const closeDialogWidget = (dialogWidget) => {
    dialogWidget.style.display = 'none';
  };

  const onMouseOverDialodWidget = (event) => {
    const dialogWidget = document.querySelector('.dialog-widget');
    const { target } = event;

    if (
      !target.closest('.desktop-head') &&
      !target.closest('.dialog-widget-content')
    ) {
      closeDialogWidget(dialogWidget);
      document.body.removeEventListener('mouseover', onMouseOverDialodWidget);
    }
  };

  /**
   * Open Megamenu on hover
   */

  $('.products a').on('mouseenter', function () {
    $(this).click();

    const dialogWidget = document.querySelector('.dialog-widget');

    if (dialogWidget) {
      document.body.addEventListener('mouseover', onMouseOverDialodWidget);
    }
  });

  // initListenenersForWidget();

  // $('.products a').on('mouseleave', function () {
  //   const dialogWidget = document.querySelector('.dialog-widget');
  //   dialogWidget.removeEventListener('mouseleave', closeDialogWidget);
  // });

  /**
   * Play video on home slider at iOs
   */
  $('body').on('click touchstart', function () {
    const videoElements = document.querySelectorAll('video[autoplay]');
    if (!videoElements) return;
    videoElements.forEach(function (el) {
      if (el.playing) {
        // video is already playing so do nothing
      } else {
        el.play();
      }
    });
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here
  // eslint-disable-next-line no-unused-vars
  const $filterLinks = $('.posts__tax-link');
  // eslint-disable-next-line no-unused-vars
  const $catLinks = $('.filter-link');
  function filterAnchor() {
    // eslint-disable-next-line no-unused-vars
    const $url = location.href;
    if ($url.indexOf('#') > -1) {
      // eslint-disable-next-line no-unused-vars
      const $term = $url.substring($url.lastIndexOf('/') + 1);
      $(`${$term}`).trigger('click');
    }
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      400
    );
  }
  filterAnchor();
  /**
   * Make elementor accordions collapsed by default
   */
  function collapseAccordions() {
    var delay = 10;
    setTimeout(function () {
      $('.elementor-tab-title').removeClass('elementor-active');
      $('.elementor-tab-content').css('display', 'none');
    }, delay);
  }
  collapseAccordions();

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
